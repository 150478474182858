import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import BreadCrumb from '@components/breadcrumb';
import Birthdays from '@views/birthdays';

const RenderBody = ({ doc }) => {
  const standard = doc.standard[0];
  const advanced = doc.advanced[0];
  const elite = doc.elite[0];

  return (
    <>
      <SEO
        title="Birthdays"
        uri="birthdays"
        desc="Check out our birthday packages."
      />
      <BreadCrumb name="Birthdays" />
      <Birthdays standard={standard} advanced={advanced} elite={elite} />
    </>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allBirthdayss.edges.slice(0, 1).pop();

  if (!doc) return null;
  return <RenderBody doc={doc.node} />;
};

export const query = graphql`
  {
    prismic {
      allBirthdayss {
        edges {
          node {
            standard {
              name
              price
              description
              button_text
            }
            advanced {
              name
              price
              description
              button_text
            }
            elite {
              name
              price
              description
              button_text
            }
          }
        }
      }
    }
  }
`;
