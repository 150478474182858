import React from 'react';
import { FormattedIcon } from '@components/icons';
import { RichText } from 'prismic-reactjs';
import { PrimaryButton } from '@components/button';

// styles
import { Container } from '@styles';
import {
  BrithdaysSection,
  Row,
  Col,
  PricingTable,
  HeaderWrapper,
  FooterWrapper,
  IconWrapper,
  Type,
  Price,
  Badge,
} from './styles';

const Birthdays = ({ standard, advanced, elite }) => {
  return (
    <BrithdaysSection>
      <Container normal>
        <Row>
          <Col>
            <PricingTable>
              <HeaderWrapper>
                <IconWrapper>
                  <FormattedIcon name="plane" />
                </IconWrapper>
                <Type>{standard.name}</Type>
                <Price>
                  <span>$</span>
                  {standard.price}
                </Price>
              </HeaderWrapper>
              <div>
                <RichText render={standard.description} />
              </div>
              <FooterWrapper>
                <PrimaryButton to="/contact">
                  {standard.button_text}
                </PrimaryButton>
              </FooterWrapper>
            </PricingTable>
          </Col>
          <Col>
            <PricingTable>
              <HeaderWrapper>
                <Badge>MOST POPULAR</Badge>
                <IconWrapper>
                  <FormattedIcon name="plane" />
                </IconWrapper>
                <Type>{advanced.name}</Type>
                <Price>
                  <span>$</span>
                  {advanced.price}
                </Price>
              </HeaderWrapper>
              <div>
                <RichText render={advanced.description} />
              </div>
              <FooterWrapper>
                <PrimaryButton to="/contact">
                  {advanced.button_text}
                </PrimaryButton>
              </FooterWrapper>
            </PricingTable>
          </Col>
          <Col>
            <PricingTable>
              <HeaderWrapper>
                <IconWrapper>
                  <FormattedIcon name="plane" />
                </IconWrapper>
                <Type>{elite.name}</Type>
                <Price>{elite.price}</Price>
              </HeaderWrapper>
              <div>
                <RichText render={elite.description} />
              </div>
              <FooterWrapper>
                <PrimaryButton to="/contact">{elite.button_text}</PrimaryButton>
              </FooterWrapper>
            </PricingTable>
          </Col>
        </Row>
      </Container>
    </BrithdaysSection>
  );
};

export default Birthdays;
